.blogArticleShortWrap img {
    width:100%;
}

p.blogExcerpt {
    color:var(--body-text);
    line-height:2;
    padding: 1rem 0 .5rem 0;
}

p.readMore {
    font-weight:500;
    color:var(--primary-blue);
}

p.readMore:hover {
    text-decoration:underline;
}

.blogPageImg {
    height: 17rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position:center;
}

/* ARTICLE PAGES */

.articlePageWrapper p {
    color:var(--body-text);
}

img.articleHeroImg {
    width:100%;
}

p.article-big {
    padding: 2rem 0 1rem 0;
    font-size:1.25rem;
}

h3.articleSubtitle {
    padding:1rem 0;
    color:var(--primary-blue);
}

ol, li {
    color:var(--body-text);
}

/* Responsive Views */
@media (max-width:576px) {
    .articleBodyImg {
        width:100%;
    }
}