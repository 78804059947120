html, body, #root {
  display: block;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background: #fff;
  color: var(--light-blue);
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  /* overflow-x: hidden; */
}

a {
  color:var(--primary-blue);
}

a:hover {
  text-decoration:none;
}

h2 {
  font-size:1.75rem;
}

h3 {
  font-size:1.25rem;
}

li {
  color:var(--primary-blue);
}

:root {
  --primary-blue: #14315B;
  --light-blue:#A2DEF9;
  --body-text: #151516;
  --warning:#dc0000;
}

::-moz-selection { 
  background: var(--primary-blue); 
  color: #ffffff;
  opacity: .5;
}

::selection { 
  background: var(--primary-blue); 
  color: #ffffff;
  opacity: .5;
}

button[type=submit] {
  transition:.2s;
}

/* Helper classes */

.tr {
  text-align:right;
}

.tc {
  text-align:center;
}

.tl {
  text-align:left;
}

.bold {
  font-weight:500;
}

.light {
  font-weight:300;
}

.pt-1 {
  padding-top:1rem!important;
}

.pt-2 {
  padding-top:2rem;
}

.pt-3 {
  padding-top:3rem;
}

.pt-4 {
  padding-top:4rem;
}

.pageDiff {
  background: #f8fbfc;
}

/* Components */

.pageSection {
  padding-top:2rem;
}

.headerWrapper {
  line-height:1;
}

.header {
  background:var(--primary-blue);
  background: linear-gradient(0deg, rgba(20,49,91,1) 50%, rgba(12,32,59,1) 100%);
  color: white;
  height:5rem;
}

.header a {
  color:white;
}

img.headerLogo {
  width: 65%;
  padding-top: .7rem;
}

/* Mobile Menu */

.mobMenuContainer {
  padding: inherit;
}

.mobMenu {
  display:none;
  position:absolute;
  z-index:11;
  width: 100%;
  background:white;
  font-weight:500;
  font-size:1.25rem;
  transition:.3s;
}

.mobMenu li {
  list-style:none;
  line-height:2.5;
}

.burgerBtn {
  position:absolute;
  display: none;
  right: 1rem;
  top: 1.5rem;
}

.burgerIcon {
  width: 2rem!important;
  height: 2rem;
}

/* Desktop Menu */

.topMenu {
  background:var(--light-blue);
}

.topMenu li {
  display: table-cell;
  vertical-align: middle;
  list-style: none;
  min-width: 7rem;
  text-align:center;
  height:2.5rem;
  text-transform: uppercase;
  transition:.2s;
  font-weight:500;
}

.menuItem {
  text-align: center;
  transition:.2s;
  border-bottom: 3px solid var(--light-blue);
}

.menuItem:hover {
  background:var(--primary-blue);
  /* border:1px solid white; */
}

.menuItem:hover li {
  color:white;
}

.activeMenu {
  background:var(--primary-blue);
}

.activeMenu li {
  color:white;
}

.menuDropDown {
  background:white;
  position:absolute;
  z-index:10;
  width: 18rem;
  transition:.2s;
}

.subMenuWrap {
  padding: .5rem 1rem;
  background: #e2f6ff;
}

.subMenuWrap:hover {
  background: var(--primary-blue);
}

.subMenuWrap:hover a {
  color:white!important;
}

.itemTitle {
  text-transform:uppercase;
  font-weight:500;
}

/* .itemSubtitle {
  border-bottom: 1px solid var(--primary-blue);
  padding-bottom: .5rem;
} */

/* FOOTER */

.footerWrapper {
  background:var(--primary-blue);
  background: linear-gradient(180deg, rgba(20,49,91,1) 0%, rgba(12,32,59,1) 100%);
  margin-top:4rem;
}

.footerLine {
  height:.5rem;
  background:var(--light-blue);
}

p.footerAddress {
  margin-top:1rem;
  color:white;
}

p.footerCredits {
  font-size: .75rem;
  margin-bottom:3rem;
  display:inline-block;
}

img.footerLogo {
  width:25rem;
  margin-top:1.5rem;
}

button.btn-primary {
  background:var(--primary-blue);
  background: linear-gradient(180deg, rgba(20,49,91,1) 0%, rgba(12,32,59,1) 100%);
  border-color:var(--primary-blue);
  padding:.3rem 2rem;
  font-size:1.5rem;
  border-radius:1rem;
}

button.btn-primary:hover {
  background: linear-gradient(180deg, rgba(20,49,91,1) 0%, rgba(12,32,59,1) 100%);
  border-color:var(--primary-blue);
  color:var(--light-blue);
}

button.btn-primary:active:focus {
  background:var(--primary-blue)!important;
  background: linear-gradient(180deg, rgba(20,49,91,1) 0%, rgba(12,32,59,1) 100%)!important;
  color:var(--light-blue)!important;
}

button.btn-secondary {
  background:transparent!important;
  color:var(--primary-blue);
  background: linear-gradient(180deg, rgba(20,49,91,1) 0%, rgba(12,32,59,1) 100%);
  border-color:var(--primary-blue);
  padding:.3rem 2rem;
  font-size:1rem;
  border-radius:1rem;
}

button.btn-secondary:hover {
  background:var(--light-blue)!important;
  color:var(--primary-blue);
}

.headerContact .faIcon {
  margin-right:.5rem;
  color: var(--light-blue);
}

.headerContact {
  text-align: right;
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 1rem;
    line-height: 1.3;
}

.footerSocial {
  margin-top:1.5rem;
  text-align:right;
}

.footerSocial .faIcon {
  width:2rem;
  height:2rem;
  margin-left:1rem;
  color:white;
}

.developer {
  color: #446e80;
  font-size: .75rem;
  display: inline-block;
}

.developer a {
  color:#446e80;
}

.developer a:hover {
  text-decoration: underline;
  color:#446e80;
}

/* Pages */

h2.sectionHead {
  color:var(--primary-blue);
  padding:2rem 0;
  text-transform:uppercase;
}

h2.sectionHead::after {
  content: '';
  width: 20rem;
  height: .25rem;
  display: block;
  position: absolute;
  margin: .75rem 0 0 0;
  background-color: var(--light-blue);
}

h2.sectionHeadAlt {
  color:var(--primary-blue);
  padding:3rem 0 0 0;
  text-transform:uppercase;
  font-weight:600;
}

h2.sectionHeadAlt::before {
  content: '';
  width: 20rem;
  height: .25rem;
  display: block;
  position: absolute;
  margin: -2rem 0 0 0;
  background-color: var(--light-blue);
}

.pageHeader {
  /* background-position: center!important; */
  height:10rem;
}

#quoteHeaderImg {
  background-image:url('/home/banner-test.jpg');
}

.pageHeader h1 {
  color:var(--primary-blue);
  margin-top:3rem;
}

.servicesPageWrapper .pageHeader h1 {
  color:white;
  margin-top:3rem;
}

/* Forms */

.quotePageWrapper label,
.contactPageWrapper label {
  display:block;
  color:var(--primary-blue);
  font-size:1.5rem;
  font-weight:300;
  padding-top: 1rem;
}

.quoteForm input,
.contactForm input,
.contactForm textarea {
  width:100%;
  font-size: 1.5rem;
  font-weight: 300;
  padding: .25rem .5rem;
  border-radius: 5px;
  border: 1px solid var(--primary-blue);
}

input[type=checkbox] {
  display: inline-block;
  width: auto;
  width: 1.5rem;
  height: 1.5rem;
  margin-right:1rem;
}

/* Common form elements */

.submitThanks {
  position: absolute;
  margin-top: -5.5rem;
  font-size: 1.25rem;
  transition:1s;
  color:var(--primary-blue);
  pointer-events:none;
}

.validationBox {
  position:absolute;
  color:var(--warning);
}

/* Responsive Views */

@media (max-width:768px) {

  .topMenu{
    display:none;
  }

  .menuItem {
    border-bottom:none;
  }

  .mobMenu {
    display:block;
  }

  .mobMenu li {
    border-bottom: 1px solid #ececec;
  }

  .burgerBtn {
    display: inline-block;
  }

}

@media (max-width:576px) {
  .headerContact {
    display: none;
  }

  img.footerLogo {
    width:100%;
  }

  .footerSocial {
    text-align: left;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .pageHeader h1 {
    color:white;
  }

  .footerWrapper .tr.col {
    text-align:left;
  }

  img.headerLogo {
    padding-top: 1.2rem;
  }

}

/* 

div, h1, h2, h3, h4, h5, h6, p, span, input, button, li {
  font-family: 'Titillium Web', sans-serif;
  font-size: 24px;
  color: #ffffff;
}

a, .link {
  font-family: 'Titillium Web', sans-serif;
  font-size: 24px;
  font-weight: 400;
  color: rgba(255,255,255,0.6);
  text-decoration: none;
  transition: all 0.14s ease;
}

a:hover, .link:hover {
  cursor: pointer;
  color: rgba(255,255,255,1);
}

a:active, .link:active {
  color: rgba(255,255,255,0.6);
}

.buttonPrimaryLarge {
  text-align: center;
  font-size: 18px;
  width: 100%;
  max-width: 360px;
  height: auto;
  margin: 20px 0 0 0;
  padding: 24px 24px 28px 24px;
  background: #fd5750;
  color: #fff;
  font-family: 'Titillium Web', sans-serif;
  font-size: 24px;
  font-weight: 600;
  border-radius: 4px;
  border: 0px;
  transition: all 0.14s ease;
  user-select: none;
  outline: none;
  text-transform: lowercase;
}

.buttonPrimaryLarge:hover {
  cursor: pointer;
  transform: scale(1.03);
}

.buttonPrimaryLarge:active {
  cursor: pointer;
  transform: scale(1);
  outline: none;
} */