.portfolioPageWrapper .slick-next {
    right:10px;
}

.portfolioPageWrapper .slick-prev {
    left: 10px;
    z-index: 5;
}

.portfolioPageWrapper .slick-dots li {
    width:10px;
    height:10px;
}

.portfolioPageWrapper .slick-dots li button:before {
    font-size:.5rem!important;
  }
