h3.cta {
  color:var(--primary-blue);
  display: inline-block;
  text-transform:uppercase;
  font-size:1.75rem;
}

.faIconCta {
  display: inline-block;
  margin:0 1rem;
  width:1.75rem!important;
  height:1.75rem;
}

.ctaTxt {
  padding:1rem 0;
}

.ctaBtn {
  padding-bottom:2rem;
}

@media (max-width:576px) {
  h3.cta {
    font-size: 1.3rem;
  }
}