
.contactPageWrapper p {
    color:var(--primary-blue);
}

.contactForm button {
    margin-top:2rem!important;
    padding-left:3rem!important;
    padding-right:3rem!important;
}

img.mapMarker {
    width:4rem;
    border-radius:10px;
    border:2px solid var(--primary-blue);
}

.mapCol {
    padding-top:2rem;
    color:var(--primary-blue)
}