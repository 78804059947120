.modalWrapper {
    background: #000000a1;
    position: fixed;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition:.3s;
}

.modalBox {
    background: var(--primary-blue);
    border:3px solid var(--light-blue);
    width: 30rem;
    height: 15rem;
    border-radius: 10px;
    margin: auto auto;
    margin-top: 25%;
    text-align:center;
}

.modalTitle {
    padding: 0 2.25rem 2.25rem 2.25rem;
    color: white;
    margin-top: -1.5rem;
}

.modalTitle span {
    background: #28a745;
    color: #f8f9fa;
    padding: 0 .5rem;
}

.modalBox button {
    margin-top: -2rem;
    border: 2px solid var(--light-blue)!important;
    font-weight: 500;
    color:white;
}

.closeX {
    text-align: right;
    margin: 1rem;
}

.closeXsymbol {
    width: 1.5rem!important;
    height: 1.5rem!important;
    cursor:pointer;
}

@media (max-width:576px) {

    .modalBox {
        width:90%;
        height: 17rem;
        margin-top: 50%;
    }
  
  }