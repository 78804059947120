li.services {
    color:var(--primary-blue);
    list-style:none;
    font-weight:500;
    line-height:2;
}

.servicesIcon {
    color:var(--light-blue);
    margin-right:1rem;
}

.pricing {
    color:var(--primary-blue);
    line-height:2;
    border-bottom:2px solid var(--light-blue);
}

.servicesExplainer {
    color:var(--primary-blue);
}

.singleServiceParagraphs {
    margin-top:2rem;
}